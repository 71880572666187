import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FAFAFA",
        },

        "& .MuiFormLabel-root.Mui-disabled": {
            color: theme.palette.primary.light,
        },

        "& .MuiFormLabel-root": {
            color: theme.palette.primary.light,
        },

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
        },
    },
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    logo: {
        width: '15vw',
        minWidth: "100px"
    },
    textfield: {
        fontWeight: "400",
        "&$Mui-disabled": {
            color: "red"
        }
    },
    disabled: {}
}));


export default function TermsAndConditions() {

    return (
        <div>
            <Typography component="h1" variant="h6" >
                VIBEフィッティングプライバシーノーティス
                </Typography>
                <Typography component="h1" variant="h6" >
                1.  一般事項
                </Typography>
            <Typography component="h1" variant="body1" >
                1.1  この個人情報に関する通知（以下「本個人情報通知」といいます。）は、お客様に対して、お客様がヒアリングエクスプレス株式会社（以下「Vibe」又は「当社」といいます。）のオンラインサービス（以下「本サービス」といいます。）を利用するときに当社が処理するお客様の個人データ（第3.1条に定義します。）及び技術的データ（第3.3条に定義します。）の収集方法及びその目的に関する情報を提供することを意図しています。当社は、お客様の個人データを、本個人情報通知、個人情報の保護に関する法律（平成15年法律第57号）その他本サービスに関連する法令及びガイドラインに従って処理します。
                </Typography>
                <Typography component="h1" variant="body1" >
                1.2  Vibeは、お客様の個人データに関するデータ管理者です。お客様は、第7条の連絡先情報を利用して当社に連絡することができます。
                </Typography>
                <Typography component="h1" variant="body1" >
                1.3  お客様が住んでいる国又は地域や本サービスによって、機能が異なる場合があります。例えば、
                </Typography>
                <Typography component="h1" variant="body1" >
                1.3.1  遠隔聴覚検査を実施するために、第三者の使用条件やプライバシーポリシーが適用される第三者のウェブサイトを使用する場合があります。
                </Typography>
                <Typography component="h1" variant="body1" >
                1.3.2  お客様の補聴器の特定のセッティングやパラメーターを当社の補聴器専門家が遠隔で変更する場合があります（以下「遠隔機能」といいます。）。
                </Typography>
                <Typography component="h1" variant="h6" >
                2.  お客様のデータをどのように収集しますか？
                </Typography>
                <Typography component="h1" variant="body1" >
                当社は、お客様の個人データをお客様、本サービス、当社の補聴器専門家及びお客様の補聴器から収集します。
                </Typography>
                <Typography component="h1" variant="h6" >
                3.  お客様の個人データを処理する目的及び法的根拠はなんですか？
                </Typography>
                <Typography component="h1" variant="body1" >
                遠隔フィッティング中に処理される個人データについて
                </Typography>
                <Typography component="h1" variant="body1" >
                3.1  お客様が本サービス及び第1.3.2条に規定されているその遠隔機能をご利用される場合、当社は、お客様の特定の個人データを、当該個人データを当社の補聴器専門家に送信し、補聴器専門家がお客様の補聴器遠隔でのチューニングやフィッティングを行うことを許可する目的で処理します。当社が処理する個人データのカテゴリは以下のとおりです。
                </Typography>
                <Typography component="h1" variant="body1" >
                3.1.1  お客様の氏名、オーディオグラム、メールアドレス、補聴器のモデル、シリアルナンバー及び補聴器の設定。
                </Typography>
                <Typography component="h1" variant="body1" >
                3.2  この情報の処理は、お客様と当社の補聴器専門家との間の遠隔フィッティングを可能にするために必要です。当社の処理の法的根拠は、本サービスを初めてご利用いただく際にお客様から提供された同意に基づきます。
                </Typography>
                <Typography component="h1" variant="body1" >
                本サービスによる技術データの収集について
                </Typography>
                <Typography component="h1" variant="body1" >
                3.3  お客様が遠隔機能をご利用される場合、当社は、お客様の氏名、メールアドレス、デバイスID等のお客様のデバイス、OS、お客様が特定の機能や動作を使用する頻度等の本サービスのご利用状況についての技術的データを収集します。お客様の個人データは、お客様を識別することができずかつ当該個人データを復元することができないように加工されます。当社は、この匿名化された情報を、お客様を識別するために他の情報と照合しません。当社は、この情報を作成したときは、当該情報に含まれる個人に関する情報の項目を公表します。
                </Typography>
                <Typography component="h1" variant="body1" >
                3.3.1  この情報の処理は、当社がメンテナンス、トラブルシューティング並びに当社が提供するサービス及び本サービスの改善における当社の正当な利益の追求に必要となります。
                </Typography>
                <Typography component="h1" variant="h6" >
                4.  お客様の個人データの共有
                </Typography>
                <Typography component="h1" variant="body1" >
                4.1  遠隔チューニング及び/又はフィッティング中のお客様の個人データのお客様を担当する補聴器専門家への送信に加え、お客様の個人データは、3.3条と同じ工程によりに匿名化され、この匿名化された情報は、上記3.2条及び3.3条で規定された目的のためにWS Audiologyグループ間（Vibeはこれに属します。）で共有されます。当社は、この情報を作成したときは、当該情報に含まれる個人に関する情報の項目を公表し、この情報をWS Audiologyグループ会社に提供するときは、あらかじめ、当該グループ会社に提供される情報に含まれる個人に関する情報の項目及びその提供の方法について公表します。
                </Typography>
                <Typography component="h1" variant="body1" >
                4.2  当社は、お客様の個人データを、当社に代わって事業運営を実行する当社のサードパーティサービスプロバイダに共有することがあります。当社は、当社が求めた事業運営を実行するために当社のサードパーティサービスプロバイダが必要とする情報のみをこれに提供し、当社は、サードパーティサービスプロバイダに対し、この情報を保護すること、他のいかなる目的でもこれを開示又は使用しない義務を課します。複数あるサービスの中で、当社は、Microsoft Azureを使用しています（アメリカ合衆国にある基本的なクラウドサービスです）。
                </Typography>
                <Typography component="h1" variant="body1" >
                4.3  法律により要求される場合、お客様の個人データは、日本国外の第三者に共有されることがあります。
                </Typography>
                <Typography component="h1" variant="body1" >
                4.3.1  例えば、召喚又は同様の法的手続に従う場合。当社が法的に許容されている範囲で、法的手続の一部として第三者にお客様の個人情報を提供する必要がある場合は、当社は、お客様に通知するために商業的に合理的な措置を講じます。又は
                </Typography>
                <Typography component="h1" variant="body1" >
                本第4.3条に記載されているこれらの第三者は、アメリカ合衆国を含むお客様の居住する国の外に所在している場合があり、そのデータ保護法は、お客様が居住する国の法律とは異なる場合があります。そのような場合、当社は、EU標準契約条項などの適切な法的過程を実施することにより、お客様の個人データを保護するための適切な安全対策が講じられていることを保証します。
                </Typography>
                <Typography component="h1" variant="h6" >
                5.  お客様の権利
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1  所定の条件に従い、お客様は、以下の権利を有します。
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.1.  お客様は、本サービスで当社が保持及び処理するお客様の個人データへのアクセスを要求する権利を有します。これにより、お客様は当社がお客様について保持している個人データのコピーを受け取り、当社が合法的に当該個人データを処理しているかを確認できます。お客様の権利を行使するには、当社サポートサービス　support-jp@vibe-hearing.comにお問い合わせください。
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.2  お客様は、当社が保有するお客様の個人データの修正を要求する権利を有します。これにより、お客様は、当社がお客様について保持している不完全又は不正確な情報を修正することができます。お客様の権利を行使するには、当社サポートサービス　support-jp@vibe-hearing.comにあるにお問い合わせください。
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.3  お客様は、お客様の個人データの消去を要求する権利を有する場合があります。これにより、当社に個人データの処理を継続する正当な理由がない場合に、お客様はその削除又は除去を要求することができます。例えば、当社が当社の法的義務を遵守するため又は法的要求を立証、履行又は防御するためなど、お客様の個人データの継続的な処理が必要な限度においては、お客様は当社に対してお客様の個人データの削除を要求できません。お客様の権利を行使するには、当社サポートサービス　support-jp@vibe-hearing.comにお問い合わせください。
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.4  お客様は、お客様の特別な状況と関連する根拠によりデータ処理に異議を述べることを望む場合、当社（又は第三者）の法的な利益に基づいてお客様の個人データを処理することに対して異議を述べる権利を有します。お客様の権利を行使するには、当社サポートサービス　support-jp@vibe-hearing.comにお問い合わせください。
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.5  お客様は、お客様の個人データの処理の制限を要求する権利を有する場合があります。これにより、例えば、お客様が当社に当該個人データの正確性又は処理の理由を確認したい場合など、お客様は、当社に、お客様に関する個人データの処理を停止するよう求めることができます。お客様の権利を行使するには、当社サポートサービス　support-jp@ ibe-hearing.comにお問い合わせください。
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.6  お客様は、お客様の個人データの第三者への転送を要求する権利（データポータビリティとも呼ばれています。）を有する場合があります。
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.7  お客様は、いつでも、お客様の同意を撤回することができます。お客様の権利を行使する場合、当社サポートサービス　support-jp@vibe-hearing.comにご連絡ください。
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.8  当社は、プライバシーの義務を真剣にとらえています。当社が適用される法律に違反したという懸念をお客様が当社に申立てた場合、当社は、できる限り早くお客様に回答し、いかなる場合であっても30日以内に回答します。
                </Typography>
                <Typography component="h1" variant="h6" >
                6.  データ保持及びセキュリティ
                </Typography>
                <Typography component="h1" variant="body1" >
                6.1  お客様と当社との関係が続く期間中、本個人情報通知に記載されている目的を達成するために必要に応じて当社は、お客様の個人データを保持します。お客様の個人データを削除する権利を行使する場合には、当社サポートサービス　support-jp@vibe-hearing.comにお問い合わせください。
                </Typography>
                <Typography component="h1" variant="body1" >
                6.2  お客様による本サービスの利用を通じてお客様のデータ及び個人情報のプライバシーを保護するため、当社は物理的、技術的及び管理上の安全対策を維持しています。当社は、継続的にセキュリティ技術を更新し、テストしています。加えて、当社は、お客様の個人データへのアクセスを、業務を遂行するためにお客様の個人データにアクセスする妥当かつ合理的な必要性があり、かつ守秘義務課されている又は機密保持の適切な法的義務の下にある従業員に制限しています。さらに、当社は、守秘義務の重要性について当社の従業員を教育し、お客様の情報のプライバシーとセキュリティを保持します。当社は、当社の従業員のプライバシー責任を履行するために適切な懲戒処分を行うことを約束します。
                </Typography>
                <Typography component="h1" variant="h6" >
                7.  連絡先情報
                </Typography>
                <Typography component="h1" variant="body1" >
                7.1  Vibeは、お客様について当社が処理する個人データのデータ管理者です。
                </Typography>
                <Typography component="h1" variant="body1" >
                7.2  本個人情報通知、お客様の権利を行使するための要求に関してのご質問、お客様の個人データの処理に関する苦情がある場合には、以下の連絡先情報をご利用ください。
                </Typography>
                <Typography component="h1" variant="body1" >
                ヒアリングエクスプレス株式会社
                </Typography>
                <Typography component="h1" variant="body1" >
                〒102-0074
                </Typography>
                <Typography component="h1" variant="body1" >
                東京都千代田区九段南1-5-6りそな九段ビルディング5F KSフロア
                </Typography>
                <Typography component="h1" variant="body1" >
                メールアドレス：dpo@wsa.com
                </Typography>
                <Typography component="h1" variant="h6" >
                8.  ウェブサイトへのリンク
                </Typography>
                <Typography component="h1" variant="body1" >
                本サービスは、WS Audiologyグループのウェブサイトへのリンクを含んでいます。これらのウェブサイトでは、別の個人情報通知に従います。詳しくは適用されるウェブサイト個人情報通知をご覧ください。
                </Typography>
                <Typography component="h1" variant="body1" >
                9.  本個人情報通知の変更
                </Typography>
                <Typography component="h1" variant="body1" >
                当社は、本個人情報通知を随時変更することができます。お客様には更新された本個人情報通知を確認することをお勧めします。本個人情報通知の変更は、本サービス又はこのページにこれらの変更が反映された時から効力を有します。本個人情報通知は、2020年10月1日に最終更新されています。
                </Typography>
        </div>
    );
}