import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import vibeImg from "./../Assets/vibetransparent.png";
import serial from "./../Assets/SerialNo.jpg";

import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';

import InputAdornment from '@material-ui/core/InputAdornment';

import { Base64 } from 'js-base64';

import {
    encode
  } from 'url-safe-base64'

import TandCs from "./TermsAndConditions";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" target="_blank" href="https://www.vibe-hearing.com/">
                Vibe
      </Link>{' '}
            {new Date().getFullYear()}
            {' - Version '}
            {process.env.VERSION}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FAFAFA",
        },

        "& .MuiFormLabel-root.Mui-disabled": {
            color: theme.palette.primary.light,
        },

        "& .MuiFormLabel-root": {
            color: theme.palette.primary.light,
        },

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
        },

        // "& .MuiFormHelperText-root": {
        //     color: theme.palette.primary.light,
        // },

        // "& .MuiFormHelperText-root Mui-error": {
        //     color: "red",
        // }
    },
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height: "56px"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    logo: {
        width: '15vw',
        minWidth: "100px"
    },
    popupimg: {
        width: '90%',
    },
}));


export default withRouter(function Home(props) {
    let leftSerialInput = useRef(null);
    let rightSerialInput = useRef(null);

    const classes = useStyles();
    const [leftDevice, setLeftDevice] = React.useState('');
    const [rightDevice, setRightDevice] = React.useState('');

    const [leftSerial, setLeftSerial] = React.useState('');
    const [rightSerial, setRightSerial] = React.useState('');

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');

    const [errorName, setErrorName] = React.useState('');
    const [helperTextName, setHelperTextName] = React.useState('');

    const [errorEmail, setErrorEmail] = React.useState('');
    const [helperTextEmail, setHelperTextEmail] = React.useState('');

    const [errorSerialLeft, setErrorSerialLeft] = React.useState('');
    const [helperTextSerialLeft, setHelperTextSerialLeft] = React.useState('');

    const [errorSerialRight, setErrorSerialRight] = React.useState('');
    const [helperTextSerialRight, setHelperTextSerialRight] = React.useState('');

    const [errorTandCs, setErrorTandCs] = React.useState('');
    const [helperTextTandCs, setHelperTextTandCs] = React.useState('');

    const [helperTextLeftAndRightEmpty, setHelperTextLeftAndRightEmpty] = React.useState('');

    const [serialNumberDialogOpen, setSerialNumberDialogOpen] = React.useState(false);
    const [deviceNameDialogOpen, setDeviceNameDialogOpen] = React.useState(false);
    const [termsAndConditionsDialogOpen, setTermsAndConditionsDialogOpen] = React.useState(false);
    const [tandCsAccepted, setTandCsAccepted] = React.useState(false);


    const handleSerialNumberDialogClickOpen = () => {
        setSerialNumberDialogOpen(true);
    };

    const handleDeviceNameDialogClickOpen = () => {
        setDeviceNameDialogOpen(true);
    };

    const handleTermsAndConditionsDialogClickOpen = () => {
        setTermsAndConditionsDialogOpen(true);
    }

    const handleClose = (value) => {
        setSerialNumberDialogOpen(false);
        setDeviceNameDialogOpen(false);
        setTermsAndConditionsDialogOpen(false);
    };

    const onSubmit = (event) => {
        event.preventDefault();
        if (validate()) {

            console.log(leftDevice);
            console.log(leftSerial);
            console.log(rightDevice);
            console.log(rightSerial);
            console.log(email);
            console.log(name);

            let leftDev = leftDevice;
            let rightDev = rightDevice;

            if (leftDev === "0")
                leftDev = "";

            if (rightDev === "0")
                rightDev = "";



            let data = leftDev + ";" + rightDev + ";" + leftSerial + ";" + rightSerial + ";" + email + ";" + name + ";firstfit";
            console.log(data);
            let dataEncoded = Base64.encode(data);
            let base64UrlSafeEncoded = encode(dataEncoded)
            console.log(base64UrlSafeEncoded);
            // let params = 'https://beta.shoeboxonline.net/vibe/?ref=' + window.btoa(data);
            let params = encodeURI('?ref=' + base64UrlSafeEncoded);

            console.log(params);
            //window.location.replace(url);

            props.history.push({ pathname: "/redirect", search: params });

        }
    }

    const validate = () => {

        let valid = true;
        if (name.trim() === "") {
            valid = false;
            setErrorName(true);
            setHelperTextName("お名前もしくはニックネームを記入してください。");
        }
        else {
            setErrorName(false);
            setHelperTextName("");
        }

        if (email.trim() === "") {
            valid = false;
            setErrorEmail(true);
            setHelperTextEmail("メールアドレスを記入してください。");
        }
        else {
            if (!validateEmail(email)) {
                valid = false;
                setErrorEmail(true);
                setHelperTextEmail("受信可能なメールアドレスを記入してください。（○○○＠internet.com.jpなど）");
            }
            else {
                setErrorEmail(false);
                setHelperTextEmail("");
            }

        }

        if ((leftDevice !== "" && leftDevice !== "0") && leftSerial.trim() === "") {
            valid = false;
            setErrorSerialLeft(true);
            setHelperTextSerialLeft("補聴器のシリアルナンバーを記入してください。");
        }
        else {
            setErrorSerialLeft(false);
            setHelperTextSerialLeft("");
        }

        if ((rightDevice !== "" && rightDevice !== "0") && rightSerial.trim() === "") {
            valid = false;
            setErrorSerialRight(true);
            setHelperTextSerialRight("補聴器のシリアルナンバーを記入してください。");
        }
        else {
            setErrorSerialRight(false);
            setHelperTextSerialRight("");

        }

        if ((leftDevice === "" || leftDevice === "0") && (rightDevice === "" || rightDevice === "0")) {
            valid = false;
            setHelperTextLeftAndRightEmpty("少なくともモデルを１つお選び下さい。");
        }

        if (!tandCsAccepted) {
            valid = false;
            setErrorTandCs(true);
            setHelperTextTandCs("「利用規約とプライバシーポリシーに同意します。」にチェックを入れて下さい。");
        }
        else {
            setErrorTandCs(false);
            setHelperTextTandCs("");
        }

        return valid;
    }

    const validateEmail = (email) => {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <img className={classes.logo} alt="Vibe" src={vibeImg} />
                {/* <Typography component="h1" variant="h4" style={{ marginBottom: "25px" }}>
                    Vibe First Fit
                 </Typography> */}
                <Typography component="h1" variant="body1" style={{ marginTop: "25px" }}>
                この度はVibe（ヴィーブ）補聴器をお選び頂き誠に有難うございます。 これから、一人一人の「聞こえ」に合わせてリモートで調整させて頂きます。 まず始めに、以下の手順に従って「聞こえのチェック」を受けてください。 その結果をもとに、あなたに最適なセッティングをさせて頂きます。 セッティングの手順は、ご登録頂いたメールアドレスに通常30分以内（遅くとも24時間以内）にお送りします。
                </Typography>

                <Typography component="h1" variant="h6" style={{ marginTop: "25px", background: "#e0e6ea", width: "100%", borderRadius: "5px" }} align="center">
                    ステップ１：左右に注意して補聴器のモデルを選択して下さい。
                </Typography>

                <form className={classes.form} onSubmit={onSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography component="h1" variant="subtitle1" align="center">
                                        左耳
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} displayEmpty
                                        fullWidth>
                                        <Select
                                            labelId="demo-simple-select-placeholder-label-label"
                                            id="demo-simple-select-placeholder-label"
                                            value={leftDevice}
                                            onChange={(event) => {
                                                setLeftDevice(event.target.value);
                                                setHelperTextLeftAndRightEmpty('');
                                                if (event.target.value === '0') {
                                                    leftSerialInput.current.value = '';
                                                    setLeftSerial('');
                                                    setErrorSerialLeft(false);
                                                    setHelperTextSerialLeft('');
                                                }
                                            }}

                                            className={classes.selectEmpty}>
                                            <MenuItem value="0">
                                                <em>なし</em>
                                            </MenuItem>
                                            <MenuItem value={"SIV1:0/18/20034D065/1"}>Vibe Mini 8</MenuItem>
                                            <MenuItem value={"SIV1:0/18/20054D064/1"}>Vibe Nano 8</MenuItem>
                                            <MenuItem value={"SIV1:0/18/222066064/1"}>Vibe S8</MenuItem>

                                        </Select>
                                        <FormHelperText style={{ color: "#839ca9" }}>左耳に使用する補聴器</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>


                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography component="h1" variant="subtitle1" align="center">
                                        右耳
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} displayEmpty
                                        fullWidth>
                                        <Select
                                            labelId="demo-simple-select-placeholder-label-label"
                                            id="demo-simple-select-placeholder-label"
                                            value={rightDevice}
                                            onChange={(event) => {
                                                setRightDevice(event.target.value);
                                                setHelperTextLeftAndRightEmpty('');
                                                if (event.target.value === '0') {
                                                    rightSerialInput.current.value = '';
                                                    setRightSerial('');
                                                    setErrorSerialRight(false);
                                                    setHelperTextSerialRight('');
                                                }
                                            }}

                                            className={classes.selectEmpty}>
                                            <MenuItem value="0">
                                                <em>なし</em>
                                            </MenuItem>
                                            <MenuItem value={"SIV1:0/18/20034D065/1"}>Vibe Mini 8</MenuItem>
                                            <MenuItem value={"SIV1:0/18/20054D064/1"}>Vibe Nano 8</MenuItem>
                                            <MenuItem value={"SIV1:0/18/222066064/1"}>Vibe S8</MenuItem>
                                        </Select>
                                        <FormHelperText style={{ color: "#839ca9" }}>右耳に使用する補聴器</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography component="h1" variant="body1" align="center" style={{ color: "red" }}>
                        {helperTextLeftAndRightEmpty}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: "15px", cursor: "pointer" }}>
                        <Link onClick={handleDeviceNameDialogClickOpen}>補聴器のモデルの確認方法はこちら</Link>
                    </Typography>

                    <Typography component="h1" variant="h6" style={{ marginTop: "50px", background: "#e0e6ea", borderRadius: "5px" }} align="center">
                        ステップ２：補聴器のシリアルナンバー記入してください。(半角）
                    </Typography>

                    <Grid container spacing={2} className={classes.form}>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography component="h1" variant="subtitle1" align="center">
                                        左耳
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={errorSerialLeft}
                                        className={classes.root}
                                        helperText={helperTextSerialLeft}
                                        autoComplete="serialNumberLeft"
                                        name="serialNumberLeft"
                                        variant="outlined"
                                        fullWidth
                                        disabled={leftDevice === "0" || !leftDevice}
                                        inputRef={leftSerialInput}                                       
                                        onChange={(event) => {
                                            setLeftSerial(event.target.value);
                                            setErrorSerialLeft(false);
                                            setHelperTextSerialLeft('');
                                        }}
                                        id="serialNumberLeft"
                                        label={leftDevice === "0" || !leftDevice ? "補聴器のモデルが選択されていません。" : "アルファベットから始まるシリアルナンバー"}
                                        autoFocus
                                    />
                                </Grid>
                            </Grid>


                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography component="h1" variant="subtitle1" align="center">
                                        右耳
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        error={errorSerialRight}
                                        className={classes.root}
                                        helperText={helperTextSerialRight}
                                        autoComplete="serialNumberRight"
                                        name="serialNumberRight"
                                        variant="outlined"
                                        disabled={rightDevice === "0" || !rightDevice}
                                        inputRef={rightSerialInput}
                                        onChange={(event) => {
                                            setRightSerial(event.target.value);
                                            setErrorSerialRight(false);
                                            setHelperTextSerialRight('');
                                        }}
                                        fullWidth
                                        id="serialNumberRight"
                                        label={rightDevice === "0" || !rightDevice ? "補聴器のモデルが選択されていません。" : "アルファベットから始まるシリアルナンバー"}
                                        autoFocus
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: "15px", cursor: "pointer" }}>
                        <Link onClick={handleSerialNumberDialogClickOpen}>シリアルナンバーの確認方法はこちら</Link>
                    </Typography>
                    <Typography component="h1" variant="h6" style={{ marginTop: "50px", background: "#e0e6ea", borderRadius: "5px" }} align="center">
                        ステップ３：補聴器のセッティング手順をご連絡するため、お名前とメールアドレスを記入してください。
                </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginTop: "35px" }}>
                            <TextField
                                error={errorEmail}
                                className={classes.root}
                                helperText={helperTextEmail}
                                variant="outlined"
                                fullWidth
                                id="email"
                                label="メールアドレス"
                                name="email"
                                autoComplete="email"
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                    setErrorEmail(false);
                                    setHelperTextEmail('')
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={errorName}
                                className={classes.root}
                                helperText={helperTextName}
                                variant="outlined"
                                fullWidth
                                name="name"
                                label="お名前もしくはニックネーム"
                                id="name"
                                autoComplete="name"
                                onChange={(event) => {
                                    setName(event.target.value);
                                    setErrorName(false);
                                    setHelperTextName('')
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox onChange={(e) => { setTandCsAccepted(e.target.checked); if (e.target.checked) { setHelperTextTandCs('') } }} color="primary" />
                            <Link href="#" onClick={(e) => { e.preventDefault(); handleTermsAndConditionsDialogClickOpen(); }} variant="body1">
                                利用規約とプライバシーポリシーに同意します。
                            </Link>

                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="body1" style={{ color: "red" }}>
                                {helperTextTandCs}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="body1" style={{ color: "red" }}>
                                {helperTextLeftAndRightEmpty}
                            </Typography>
                        </Grid>


                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        「聞こえのチェック」を開始します。
                    </Button>
                </form>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={serialNumberDialogOpen} style={{ padding: "25px" }}>
                <Box
                    mt={2}
                    mb={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <img className={classes.popupimg} alt="Vibe" src={serial} />
                </Box>
                <Typography align="center" component="h1" variant="body1" style={{ marginTop: "10px" }}>
                保証書 もしくは 取扱説明書の裏表紙 に上記のようなシールが貼られていますので、そちらにてご確認ください。 （上記写真は例です）

                </Typography>


                <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    className={classes.submit}
                    onClick={handleClose}>
                    閉じる
                    </Button>
            </Dialog>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={deviceNameDialogOpen} style={{ padding: "25px" }}>
                <Box
                    mt={2}
                    mb={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <img className={classes.popupimg} alt="Vibe" src={serial} />
                </Box>
                <Typography align="center" component="h1" variant="body1" style={{ marginTop: "10px" }}>
                    保証書に上記のようなシールが貼られていますので、そちらにてご確認ください（上記写真は例です）。
                </Typography>


                <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    className={classes.submit}
                    onClick={handleClose}>
                    閉じる
                    </Button>
            </Dialog>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={termsAndConditionsDialogOpen} style={{ padding: "10px" }}>
                <TandCs />
                <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    className={classes.submit}
                    onClick={handleClose}>
                    閉じる
                    </Button>
            </Dialog>
        </Container>
    );
})