import React from "react";
import { Route, Switch } from "react-router-dom";
import Config from "./Config";
import Redirect from "./Redirect";
import Summary from './Summary';

export const Routes = () => (
    <div>
      <Switch>
        <Route exact path="/" component={Config} />        
        <Route exact path="/redirect" component={Redirect} />        
        <Route exact path="/summary" component={Summary} />        
      </Switch>
    </div>
  );