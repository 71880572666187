import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import CircularProgress from '@material-ui/core/CircularProgress';

import vibeImg from "./../Assets/vibetransparent.png";
import Link from '@material-ui/core/Link';


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FAFAFA",
        },

        "& .MuiFormLabel-root.Mui-disabled": {
            color: theme.palette.primary.light,
        },

        "& .MuiFormLabel-root": {
            color: theme.palette.primary.light,
        },

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
        },
    },
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    logo: {
        width: '15vw',
        minWidth: "100px"
    },
    textfield: {
        fontWeight: "400",
        "&$Mui-disabled": {
            color: "red"
        }
    },
    disabled: {}
}));


export default function Redirect() {
    const classes = useStyles();

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let ref = params.get("ref");

    let sBurl = 'https://www.shoeboxonline.com/vibejapan/?refid=' + ref;

    const [url] = React.useState(sBurl);

    window.location.replace(url);
    // window.open(url, '_blank');
    

    const navigate = () => {
        window.location.replace(url);
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <img className={classes.logo} alt="Vibe" src={vibeImg} />
                {/* <Typography component="h1" variant="h4" style={{ marginBottom: "25px" }}>
                    Vibe First Fit
                 </Typography> */}
                <CircularProgress style={{ marginTop: "50px" }} color="secondary" />
                <Typography component="h1" variant="body1" style={{ marginTop: "50px" }}>
                    テスト開始までしばらくお待ち下さい。
                </Typography>
                <Typography component="h1" variant="body2" style={{ marginTop: "5px" }}>
                    <Link onClick={navigate} style={{ cursor: "pointer" }}>もし画面が切り替わらない場合は、こちらをクリックして下さい。</Link>
                </Typography>
            </div>
        </Container>
    );
}